import React from 'react';
import Fade from "react-reveal/Fade"

const SplitSection = ({ id, primarySlot, secondarySlot, reverseOrder }) => (
  <section id={id} className="pt-20 px-4 overflow-hidden">
    <div className="container mx-auto items-center flex flex-col lg:flex-row">
      <Fade up>
        <div className="flex-grow lg:w-1/2">{primarySlot}</div>
      </Fade>
      <Fade up>
        <div className={`flex-grow mt-10 lg:mt-0 w-full lg:w-1/2 ${reverseOrder && `order-last lg:order-first`}`}>
          {secondarySlot}
        </div>
      </Fade>
    </div>
  </section>
);

export default SplitSection;